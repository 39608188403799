import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

// t=Webchat::GenerateToken.call(2881831)
// "eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9NYkdWaFpGOXBaR2tESi9rciIsImV4cCI6bnVsbCwicHVyIjoid2ViY2hhdCJ9fQ%3D%3D--93f126339f54c709b18a8ee080e1d67d50b3659eccf2055cd735af589e201bf2649bc003f7f6b8c666bc8319ed26a12b5dceefd94c7f50e80825e7d97c37342b"
const params = new URLSearchParams(window.location.href.split('?')[1]);
const authToken = params.get('auth_token');

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (authToken)
      config.headers.Authorization = `LfWcA ${authToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
