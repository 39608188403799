import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { store, dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload.conversation;

      if (conversation) {
        if (action.payload.onlyAppendIfAny) {
          const conversationState = state.conversations.byId[conversation.id]
          conversationState.messages = conversationState.messages.concat(conversation.messages);
        } else {
          state.conversations.byId[conversation.id] = conversation;
          state.activeConversationId = conversation.id;
          if (!state.conversations.allIds.includes(conversation.id)) {
            state.conversations.allIds.push(conversation.id);
          }
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/webchat/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data.data.contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/webchat/conversations');
      dispatch(slice.actions.getConversationsSuccess(response.data.data.conversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(onlyAppendIfAny = false) {
  let after = ''
  if (onlyAppendIfAny) {
    const chatState = store.getState().chat
    const conversations = chatState.conversations
    if (conversations && chatState.activeConversationId && conversations.byId[chatState.activeConversationId]) {
      const conversation = conversations.byId[chatState.activeConversationId]
      if (conversation) {
        const messages = conversation.messages
        let lastMsgId = null
        messages.forEach(msg => {
          if (!lastMsgId || msg.id > lastMsgId) {
            lastMsgId = msg.id;
          }
        });
        if (lastMsgId) {
          after = `?after=${lastMsgId}`
        } else {
          return null
        }
      }
    }
  }
  return async () => {
    if (!onlyAppendIfAny) {
      dispatch(slice.actions.startLoading());
    }
    try {
      const response = await axios.get(`/v1/webchat/conversation${after}`);
      if (response.data.data.conversation) {
        dispatch(slice.actions.getConversationSuccess({conversation: response.data.data.conversation, onlyAppendIfAny}));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/v1/webchat/on_read', {
        // params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reply(message) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/v1/webchat/reply', {
        body: message.message
      });
      // dispatch(slice.actions.replySuccess({ body }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/webchat/participants', {
        // params: { conversationKey },
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
